@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:wght@400;700&display=swap" );
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
#root{
  height:100vh
}
.App{
  height: 100%;
}
*{
  margin: 0;
  padding: 0;
}
body{
  background-color: #1b1c1e;
  color: aliceblue;
  overflow: hidden;
  height: 100vh    ; 
  padding: 0 10px;
  font-family: 'Roboto'
}
ul {
  list-style: none;
}
a{
  text-decoration: none;
  outline: none;
  color: aliceblue;
  font-size: 12px;
}
table {
  border-collapse: separate;
  border-spacing: 0 1em;
  overflow-y:scroll;
  height: 650px;
  display: block;
}

input {
  text-align: center;
  width: 45px;
  background: none;
  border: none;
  color: aliceblue;
  outline: none;
  font-family: 'Roboto';
  font-weight: 600;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
-webkit-appearance: none;
appearance: none;
}
/* - ---------------*/
.messages_alert{
  display: flex;
  align-items: center;
  top: 6%;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  left: 40%;
  gap: 1rem;
}
/* ---------------- */
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  pointer-events: none; /* Disable pointer events on the overlay */
  z-index: 1; /* Ensure the overlay appears above other content */
}
.divider {
  width: 100%; /* Width of the vertical divider */
  background-color:rgba(13,13,13,255); /* Color of the divider */ 
  min-height: 2px;
}
/* ----------------- */
.container{
  background-color: #0e0e0e;
  display: flex;
  flex-direction: row;
  height: 95%;
}

.old_coef-enter {
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 1s ease, transform 1s ease;
}

.old_coef-enter-active {
  opacity: 1;
  transform: translateX(0);
}

.old_coef-exit {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1s ease, transform 1s ease;
}

.old_coef-exit-active {
  opacity: 0;
  transform: translateX(20px);
}

.old_coef{
  border-radius: 24px;
  background-color: black;
  padding: 1px 10px;
  font-size: 14px;
  font-weight: 600;
  
}