.float_input{
    font-size: 18px;
    flex-basis: 62%;
}
.make_bids{
    width: 80%;
    display: grid;
    grid-template-columns:  1fr 2fr; /* Two columns of equal width */

}
.bidding{
    padding: 20px;
    display: grid;
    grid-template-rows:repeat(2, 1fr); /* Two columns of equal width */
    gap: 10px; 
}
.bids{
    display: flex;
    flex-direction: row;
    height: 173px;
    gap: 10px;
    margin-left: 10px;
    padding: 5px;
    position: relative;
    
}
.bid{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(31 33 34);
    border-radius: 3%;
    padding:0 1rem;
    justify-content: space-evenly;
}

.right{
    width: 100%;
    flex-basis: 75%;
    display: grid;
    grid-auto-rows: 0fr 10fr 3fr;
}
.bid_input{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 15px;
    background-color: rgba(13,13,13,255);
    padding: 10px;
    border-radius: 20px;
    padding: 5px 10px;
    justify-content: center;

}
.btn_number {
    width: 22px;
    height: 17px;
    border: 1px solid #ccc;
    cursor: pointer;
    color: #ccc;
    background: none;
    border-radius: 12px;
    text-align: center;
  
}
.grid_item_number{
    border: none;
    border-radius: 24px;
    background-color: rgba(20,20,20,255);
    color: #6d6b78;
    text-align: center;
}
.bid_commit{
    margin: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.chose_bids{
    background-color: rgba(13,13,13,255);
    display: flex;
    padding: 0px 18px;
    padding-left: 0;
    border-radius: 20px;
    margin-top: 0.5rem;
    align-items: center;
    position: relative;
}


.chose_buttons{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns of equal width */
    grid-template-rows: repeat(2, 1fr); /* Two rows of equal height */
    gap: 5px; /* Gap between grid items */
}

.type_bidding_active{
    align-items: center;
    display: flex;
    color: aliceblue;
    background-color: #2c2d31;
    border-radius: 24px;

}
.type_bidding{
    padding: 5px 0 ;
    font-weight: 300;
}

.type_bidding span:hover{
    color: red;
    cursor: pointer;
}
.chose_type_bidding .my{
    padding-left: 40px;
    padding-right: 40px;
}
.chose_type_bidding .all{

    padding-left: 40px;
    padding-right: 40px;
}
.chose_type_bidding{
    padding: 0;
}
.color{
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(39,159,9,255);
    border: 1px solid #e1e7eda1;
    border-radius: 17px;
    height: 100%;
}

.await_round{
    font-size: 15px;
    text-align: center;
    color: rgb(201, 201, 201);
}

/* ----  AUTO BID-------- */
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 100%;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 16px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}
  
input:checked + .slider {
    background-color: #279f09;
}
  
input:focus + .slider {
    box-shadow: 0 0 1px #279f09;
} 
  
input:checked + .slider:before {
    transform: translateX(16px);
} 

.oto{
    display: none;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
.oto p{
    font-size: 10px;
}
.auto_bid{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
.auto_cash{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
.how_much_bid{
    font-weight: 600;
    font-family: 'Roboto'; 
    text-shadow: 1px 1px 2px rgba(11, 15, 1, 3);
}

.button_make_bid{
    font-weight: 300;
    font-family: 'Roboto'; 
    text-shadow: 1px 1px 2px rgba(11, 15, 1, 3);
    
}