header{
    padding-top: 1em;
    background-color: rgb(31 33 34);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.how_to_play{
    display: flex;
    flex-direction: row;
    gap: 3px;
    border: 1px solid orange;
    background-color: orange;
    border-radius: 24px;
    padding: 1px 6px;
    color: black;
}

.menu_icon{
    display: flex;
    align-items: center;
    justify-content: center;
}
.right_up_icons{
    display: flex;
    margin-right: 1em;
    gap: 10px;
    align-items: center;
}
.divider_new{
    width: 100%; 
    background-color: rgb(130 128 138);
    width: 1px;
    height: 23px;
}