.fake_bidders{
    background-color: rgb(31 33 34);
    /* display: flex;
    flex-direction: column; */
    align-items: center;
    display: grid;
  grid-template-columns: 1fr; /* Single column */
  grid-template-rows: 90% 10%;
    margin-left: 0.5rem;
    position: relative;
    border-radius: 5px;
    margin-top: 0.5rem;

}
.wrapper_one{
    padding-left: 0.5rem ;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    align-self: start;
}
.production{
    display: grid;
  grid-template-columns: 1fr auto; /* Two columns, the first takes remaining space, the second takes content width */
  width: 100%; /* Set a fixed width for the grid container */
  justify-content: space-between;
  font-size: 10px;
  background-color: black;
  padding: 2;
  position: absolute;
  align-self: end;
  border: 1px solid rgba(255, 255, 255, 0.329);
}
.production .left , .production .right{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding-left: 4px;
}
.chose_bids{
    background-color: rgba(13,13,13,255);
    display: flex;
    padding: 0px 18px;
    padding-left: 0;
    border-radius: 20px;
    margin-top: 0.5rem;
    align-items: center;
    position: relative;
}
.chosing_bid  {
    color: #6d6b78;
    padding-top: 5px;
    padding-bottom: 5px;
}
.chosing_bid:nth-child(2) {
    margin-right: 10px; /* Adjust the gap value as needed */
  }
.all_chose_bid {
    align-items: center;
    display: flex;
    color: aliceblue;
    padding: 3px 7px;
    border-radius: 24px;
    margin-right: 10px;
    height: 100%;
    background-color: #2c2d31;
    border-radius: 24px;
  
}

.info_bids{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-right: 4px;
    align-items: center;
}

.last_bidders{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    font-size: 12px;
    color: #6d6b78;
    border: 1px solid #504f52;
    padding: 2px;
    border-radius: 15px;
    padding-right: 10px;
    background-color: #2f2f30;
    height: 100%;
}
.bidders_table {
    width: 100%;
    padding-right: 4px;
    overflow: hidden;
}
.headers{
    display: grid;
    grid-template-columns: 40% 30% 30%;
    font-size: 10px;
    color: #6d6b78;
    margin-bottom: 5px;
}
.users{
    padding-left: 2px;
}

.win_cell, .win_cell_header{
    display: flex;
    justify-content: end;
    padding-right: 8px;
}
.win_cell{
    margin-right: 4px;
}
.coef_win_cell{
    gap: 10px;
}
.coef_win_cell .win_coef_fake{
    font-size: 12px;
    border-radius: 24px;
    background-color: black;
    padding: 3px 6px;
    font-weight: 700;
}

.user_field{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    font-size: 12px;}

.table {
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style:none;
    gap: 3px;
    overflow-y: auto;
    height: 640px;
    scroll-behavior: auto;
    display: flex;
    flex-direction: column;
}
.table::-webkit-scrollbar{
    width: 0;
}

.row {
    display: grid;
    grid-template-columns: 40% 30% 30%;
    background-color:rgba(13,13,13,255);
    border-radius: 6px;

    animation: fade-in 0.2s ease;
}
@keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }


.cell {
    align-items: center;

    padding: 2px;
    display: flex;
    text-align: center;
    
}
.img_bidder{
    border-radius: 50%;
     width: 20%; 
     padding-left: 2px;
}

/* ----Color Win Coef------- */
.lessThan2{
    color: rgb(89 173 233);
}
.between2and10{
    color: #8c46f1;
}
.moreThan10{
    color: #C628a2;
}