.coefs_board{
    position: absolute;
    top: 42%;
    left: 40%; display: none;
    font-size: 90px;
    font-weight: 900;
}

/* ------glow--------------- */
.glow{
    position: absolute;
    top: 33%;
    left: 19%;
    width: 53%;
    filter: blur(52px);
    height: 30%;
    border-radius: 54%;
    opacity: 0;
    transition: opacity 2s ease-in-out, background-color 1s ease-in-out ;
}
