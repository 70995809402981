@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
#root{
  height:100vh
}
.App{
  height: 100%;
}
*{
  margin: 0;
  padding: 0;
}
body{
  background-color: #1b1c1e;
  color: aliceblue;
  overflow: hidden;
  height: 100vh    ; 
  padding: 0 10px;
  font-family: 'Roboto'
}
ul {
  list-style: none;
}
a{
  text-decoration: none;
  outline: none;
  color: aliceblue;
  font-size: 12px;
}
table {
  border-collapse: separate;
  border-spacing: 0 1em;
  overflow-y:scroll;
  height: 650px;
  display: block;
}

input {
  text-align: center;
  width: 45px;
  background: none;
  border: none;
  color: aliceblue;
  outline: none;
  font-family: 'Roboto';
  font-weight: 600;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
-webkit-appearance: none;
appearance: none;
}
/* - ---------------*/
.messages_alert{
  display: flex;
  align-items: center;
  top: 6%;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  left: 40%;
  grid-gap: 1rem;
  gap: 1rem;
}
/* ---------------- */
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  pointer-events: none; /* Disable pointer events on the overlay */
  z-index: 1; /* Ensure the overlay appears above other content */
}
.divider {
  width: 100%; /* Width of the vertical divider */
  background-color:rgba(13,13,13,255); /* Color of the divider */ 
  min-height: 2px;
}
/* ----------------- */
.container{
  background-color: #0e0e0e;
  display: flex;
  flex-direction: row;
  height: 95%;
}

.old_coef-enter {
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 1s ease, transform 1s ease;
}

.old_coef-enter-active {
  opacity: 1;
  transform: translateX(0);
}

.old_coef-exit {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1s ease, transform 1s ease;
}

.old_coef-exit-active {
  opacity: 0;
  transform: translateX(20px);
}

.old_coef{
  border-radius: 24px;
  background-color: black;
  padding: 1px 10px;
  font-size: 14px;
  font-weight: 600;
  
}
header{
    padding-top: 1em;
    background-color: rgb(31 33 34);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.header_how_to_play__m65f_{
    display: flex;
    flex-direction: row;
    grid-gap: 3px;
    gap: 3px;
    border: 1px solid orange;
    background-color: orange;
    border-radius: 24px;
    padding: 1px 6px;
    color: black;
}

.header_menu_icon__1Av5d{
    display: flex;
    align-items: center;
    justify-content: center;
}
.header_right_up_icons__235CD{
    display: flex;
    margin-right: 1em;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}
.header_divider_new__1sD8_{
    width: 100%; 
    background-color: rgb(130 128 138);
    width: 1px;
    height: 23px;
}
.message-alert_message_alert__1qGnG{
    display: flex;
    flex-direction: row;
    align-items: center;
    border:1px solid greenyellow;
    padding-left: 30px;
    width: 290px;
    border-radius: 54px;
    justify-content: space-between;
    background-color: #034203;
    transition: transform 1s ease-in-out;
    
}
.message-alert_announcement__1whun, .message-alert_win_info__1iSKh{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.message-alert_announcement__1whun{
    padding:10px;
}

.message-alert_win_info__1iSKh{
    padding: 2px 21px;
    border-radius: 33px;
    border: 1px solid #f0f8ff82;
    margin: 5px ;
  background-color: #279f09;
  position: relative;
  overflow: hidden;
  height: 42px;
  justify-content: center;
}
.message-alert_star_img__1uxfL{
  position: absolute;
  top: -4px;
  left: -11px;
  width: 122%;
  height: 151%;
  z-index: 0;
}
.message-alert_close_button__3TAJ3::before {content: 'x';  
    color: #8b8a8a;
    font-weight: 900;
    font-family: Arial, sans-serif;
    content: 'x';  
}

#message-alert_win_amount__1FpSJ{
    text-shadow: 1px 1px 2px rgba(11, 15, 1, 3);
    color: white;
}

.FakeBidders_fake_bidders__2-L5Z{
    background-color: rgb(31 33 34);
    /* display: flex;
    flex-direction: column; */
    align-items: center;
    display: grid;
  grid-template-columns: 1fr; /* Single column */
  grid-template-rows: 90% 10%;
    margin-left: 0.5rem;
    position: relative;
    border-radius: 5px;
    margin-top: 0.5rem;

}
.FakeBidders_wrapper_one__z_eDf{
    padding-left: 0.5rem ;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    align-self: start;
}
.FakeBidders_production__12jro{
    display: grid;
  grid-template-columns: 1fr auto; /* Two columns, the first takes remaining space, the second takes content width */
  width: 100%; /* Set a fixed width for the grid container */
  justify-content: space-between;
  font-size: 10px;
  background-color: black;
  padding: 2;
  position: absolute;
  align-self: end;
  border: 1px solid rgba(255, 255, 255, 0.329);
}
.FakeBidders_production__12jro .FakeBidders_left__2gybO , .FakeBidders_production__12jro .FakeBidders_right__VHgoR{
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    padding-left: 4px;
}
.FakeBidders_chose_bids__1uIf7{
    background-color: rgba(13,13,13,255);
    display: flex;
    padding: 0px 18px;
    padding-left: 0;
    border-radius: 20px;
    margin-top: 0.5rem;
    align-items: center;
    position: relative;
}
.FakeBidders_chosing_bid__24kH_  {
    color: #6d6b78;
    padding-top: 5px;
    padding-bottom: 5px;
}
.FakeBidders_chosing_bid__24kH_:nth-child(2) {
    margin-right: 10px; /* Adjust the gap value as needed */
  }
.FakeBidders_all_chose_bid__1ZGDi {
    align-items: center;
    display: flex;
    color: aliceblue;
    padding: 3px 7px;
    border-radius: 24px;
    margin-right: 10px;
    height: 100%;
    background-color: #2c2d31;
    border-radius: 24px;
  
}

.FakeBidders_info_bids__18V-K{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-right: 4px;
    align-items: center;
}

.FakeBidders_last_bidders__2sgjY{
    display: flex;
    flex-direction: row;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
    font-size: 12px;
    color: #6d6b78;
    border: 1px solid #504f52;
    padding: 2px;
    border-radius: 15px;
    padding-right: 10px;
    background-color: #2f2f30;
    height: 100%;
}
.FakeBidders_bidders_table__gdpGp {
    width: 100%;
    padding-right: 4px;
    overflow: hidden;
}
.FakeBidders_headers__1R_ZE{
    display: grid;
    grid-template-columns: 40% 30% 30%;
    font-size: 10px;
    color: #6d6b78;
    margin-bottom: 5px;
}
.FakeBidders_users__2zkeY{
    padding-left: 2px;
}

.FakeBidders_win_cell__2QEa5, .FakeBidders_win_cell_header__NZLgL{
    display: flex;
    justify-content: end;
    padding-right: 8px;
}
.FakeBidders_win_cell__2QEa5{
    margin-right: 4px;
}
.FakeBidders_coef_win_cell__2S6su{
    grid-gap: 10px;
    gap: 10px;
}
.FakeBidders_coef_win_cell__2S6su .FakeBidders_win_coef_fake__3gIQN{
    font-size: 12px;
    border-radius: 24px;
    background-color: black;
    padding: 3px 6px;
    font-weight: 700;
}

.FakeBidders_user_field__2HSNG{
    display: flex;
    flex-direction: row;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
    font-size: 12px;}

.FakeBidders_table__1E_Iy {
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style:none;
    grid-gap: 3px;
    gap: 3px;
    overflow-y: auto;
    height: 640px;
    scroll-behavior: auto;
    display: flex;
    flex-direction: column;
}
.FakeBidders_table__1E_Iy::-webkit-scrollbar{
    width: 0;
}

.FakeBidders_row__Zuv9D {
    display: grid;
    grid-template-columns: 40% 30% 30%;
    background-color:rgba(13,13,13,255);
    border-radius: 6px;

    animation: FakeBidders_fade-in__10IBg 0.2s ease;
}
@keyframes FakeBidders_fade-in__10IBg {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }


.FakeBidders_cell__bM8Am {
    align-items: center;

    padding: 2px;
    display: flex;
    text-align: center;
    
}
.FakeBidders_img_bidder__1ar8-{
    border-radius: 50%;
     width: 20%; 
     padding-left: 2px;
}

/* ----Color Win Coef------- */
.FakeBidders_lessThan2__2BTot{
    color: rgb(89 173 233);
}
.FakeBidders_between2and10__KZ2Uk{
    color: #8c46f1;
}
.FakeBidders_moreThan10__XMSwZ{
    color: #C628a2;
}
.rightsection_right__1opwW{
    width: 100%;
    flex-basis: 75%;
    display: grid;
    grid-auto-rows: 0fr 10fr 3fr;
}
/* --------------------PreviousCoefs---------------------------- */
.rightsection_basic_old_coef_div__3bAEa{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    padding: 12px;
    align-items: center;
    justify-content: center;
    max-height: 15px;
    position: relative;
}
.rightsection_upper_field_old_coefs__1fQcz{
    margin-bottom: 0;
}
.rightsection_lower_old_coef_list__vPGbn{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 5px;
    gap: 5px;
    padding: 3px 12px;
    align-items: center;
    justify-content: start;
    height: 100%;
    width: 100%;
}
.rightsection_lower_field_old_coefs__YXeRi{
    background-color: rgb(41 41 49);
}
.rightsection_hidden_old_coef__15pH4{
    font-size: 12px;
}
.rightsection_old_coef__1nBbt{
    border-radius: 24px;
    background-color: black;
    padding: 1px 10px;
    font-size: 14px;
    font-weight: 600;
    
}

.rightsection_make_darker__1LR7s{
    height: 46px;
    position: absolute;
    width: 65%;
    top: 7%;
    left: 37%;
    background: linear-gradient(to right, rgb(1 1 1 / 0%), rgb(0 0 0 / 49%));
}
.rightsection_more_old_coefs__VmTo7{
    z-index: 1;
    position: absolute;
    border-radius: 24px;
    background-color: #2c2c2e;
    overflow: hidden;
    border: 1px solid rgba(240, 248, 255, 0.20);
}
.rightsection_images_old_coefs__KAnnb{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
}
.rightsection_more_old_coefs__VmTo7:hover{
    cursor: pointer;
}
.rightsection_heart_more_coefs__ylOTT{
    transition: transform 0.5s ease;
}
.rightsection_lessThan2__lN83K{
    color: rgb(89 173 233);
}
.rightsection_between2and10__1MEev{
    color: #8c46f1;
}
.rightsection_moreThan10__1SqID{
    color: #C628a2;
}

/* - -----------GAME_--------------------------------------*/
.rightsection_game__PpQw1 {
    position: relative;
    overflow: hidden;
    margin: 1em;
    margin-bottom: 10px;
    margin-top: 0;
}

.rightsection_viewport__2BR8A {
    width: 100%; 
    height: 100%;
    display: inline-block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #f0f8ff1c;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 35px;
}

.rightsection_rotating_image__2kJTf {
    position: absolute;
    width: 200%;
    height: 180%;
    right: -4%;
    top: 7%;
    scale: 280%;
    animation: rightsection_rotate__1TGHP 30s linear infinite;
    /* animation-play-state: paused; */
    object-fit: cover;
    transform-origin: center;
}

@keyframes rightsection_rotate__1TGHP {
    0% {
        transform: rotate(0deg); /* Start from 0 degrees */
    }
    100% {
        transform: rotate(360deg); /* Rotate to 360 degrees (full circle) */
    }
}
/* ------ PLANE aviator---------- */
.rightsection_plane__hzR2D{
    width: 150px; 
    height: 150px;
    background-image: url(/static/media/aviator.08b99135.gif); /* URL to your plane image */
    background-size: cover;
    position: absolute;
    z-index: 10;
}
svg{
    position: absolute;
}

.rightsection_plane_flying__hpYSe{
    animation: rightsection_takeOff__1Onkh 7s ease-in-out, rightsection_upAndDown__3L8jQ 5s ease-in-out infinite
}


.rightsection_plane_flyaway__21Kcd{
    animation: rightsection_away__1EY5Q 2s ease-in-out
}
@keyframes rightsection_takeOff__1Onkh {
    0% {
        bottom: 0%;
        left: 0%;
        transform: translate(10%, 10%);
    }
    
    67%{
        bottom: 70%; 
        left: 60%;
        transform: translate(60%, 10%);
    }
    100%{
        bottom: 70%; 
        left: 60%;
        transform: translate(60%, 1000%);
    }
  }

@keyframes rightsection_upAndDown__3L8jQ {
    0%, 100% {  
        transform: translate(14%, 10%);
    }
    97%{
        transform: translate(14%, 10%);
    }
    50% {

      transform: translate(60%, 89%);
    }
    100%{
        transform: translate(14%, 10%);
    }
  }

@keyframes rightsection_away__1EY5Q {
    0% {
        transform: translate(0, 0);
    }
    100% {
        left: 110%;
        bottom:110% ;
        transform: translate(-10%, -50%);
    }
}




.rightsection_gone_away__2cYP1{
    font-size: 30px;
    position: absolute;
    top: 34%;
    left: 43%;
    
    font-weight: 600;
}

/* ------------------'Coordinates'  game----------*/
.axis_y_axis__2b19c{
    position: absolute;
    height: 95%;
    width: 5%;
    overflow: hidden;
}
.axis_x_axis__3rlfz{
    position: absolute;
    width: 96%;
    bottom: 0;
    right: -1%;
    height: 6%;
    overflow: hidden;
}
.axis_coordinate_x__2VJia, .axis_coordinate_y__3wjug{
    background-color: rgb(240 248 255 / 21%);
    position: absolute;
    
}

.axis_coordinate_y__3wjug{
    width: 1px;
    height: 99%;
    right: 0;
}
.axis_coordinate_x__2VJia{
    height: 1px;
    width: 100%;
}
.axis_x_dots__2x0l_{
    display: flex;
    flex-direction: row;
    grid-gap: 10%;
    gap: 10%;
    align-items: center;
    height: 100%;
    padding-left: 5%;
    animation: axis_moveRightToLeft__3yWQS 25s linear infinite;
    
}
.axis_y_dots__19jQc{
    display: flex;
    flex-direction: column;
    grid-gap: 9%;
    gap: 9%;
    height: 97%;
    align-items: center;
    animation: axis_moveUpToDown__3d6Gm 25s linear infinite;
}
.axis_dot_right__UpihO{
    background: #06619a;
}
.axis_dot_left__3mkR6{
    background: white;
}

.axis_dot__1hIFC {
    width: 5px;
    height: 5px;
    border-radius: 50%;
}
#axis_x_dots_clone__3CXlM{
    position: relative;
    right: -94%;
    top: -103%;
    animation: axis_moveRightToLeft__3yWQS 25s linear infinite;
}

#axis_y_dots_clone__3w3AE{
    bottom: 194%;
    position: relative;
    animation: axis_moveUpToDown__3d6Gm 25s linear infinite;
}
@keyframes axis_moveRightToLeft__3yWQS {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}
@keyframes axis_moveUpToDown__3d6Gm {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(100%);
    }
}
 
/* -------------------TIME BAR ---------------*/
.UI_time_bar__295bH{
    width: 100%;
    background-color: #1b1c1e;
    border-radius: 24px;
    height: 6px;
    
}
.UI_time_bar__295bH .UI_filler__1Aw3Z{
    height: 6px;
    background: #e20637;
    border: 24px;
    animation: UI_no_width__3PhgA 5s linear infinite;

}

@keyframes UI_no_width__3PhgA{
  0%{
    width: 100%;
  }
  100%{
    width: 0%;
  }
}
/*------------------------- StartRound ---------------*/
.UI_propeller__oeukw{
    background-image: url(/static/media/propeller.51ba0afa.png);
    width: 149px;
    height: 141px;
    background-size: cover;
    position: absolute;
    bottom:111%;
    right: 32%;
    animation: UI_rotate__nTxzk 1.2s linear infinite;
}

@keyframes UI_rotate__nTxzk {
    0% {
      transform: rotate(0deg); /* Rotate 0 degrees at the start */
    }
    100% {
      transform: rotate(360deg); /* Rotate 360 degrees at the end */
    }
  }

.UI_start_round__t89am{
    position: absolute;
    top: 50%;
    left: 32%;
    font-family: 'Roboto';
}
.UI_start_round__t89am h3{
    font-size: 40px;
    font-weight: 300;
}
.GameSection_coefs_board__2Sg0Z{
    position: absolute;
    top: 42%;
    left: 40%; display: none;
    font-size: 90px;
    font-weight: 900;
}

/* ------glow--------------- */
.GameSection_glow__2ARmU{
    position: absolute;
    top: 33%;
    left: 19%;
    width: 53%;
    filter: blur(52px);
    height: 30%;
    border-radius: 54%;
    opacity: 0;
    transition: opacity 2s ease-in-out, background-color 1s ease-in-out ;
}

.make_bids_float_input__1QeRz{
    font-size: 18px;
    flex-basis: 62%;
}
.make_bids_make_bids__1ws2r{
    width: 80%;
    display: grid;
    grid-template-columns:  1fr 2fr; /* Two columns of equal width */

}
.make_bids_bidding__dUogB{
    padding: 20px;
    display: grid;
    grid-template-rows:repeat(2, 1fr); /* Two columns of equal width */
    grid-gap: 10px;
    gap: 10px; 
}
.make_bids_bids__4hFu-{
    display: flex;
    flex-direction: row;
    height: 173px;
    grid-gap: 10px;
    gap: 10px;
    margin-left: 10px;
    padding: 5px;
    position: relative;
    
}
.make_bids_bid__zND_k{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(31 33 34);
    border-radius: 3%;
    padding:0 1rem;
    justify-content: space-evenly;
}

.make_bids_right__2we07{
    width: 100%;
    flex-basis: 75%;
    display: grid;
    grid-auto-rows: 0fr 10fr 3fr;
}
.make_bids_bid_input__1hYGG{
    display: flex;
    align-items: center;
    flex-direction: row;
    grid-gap: 15px;
    gap: 15px;
    background-color: rgba(13,13,13,255);
    padding: 10px;
    border-radius: 20px;
    padding: 5px 10px;
    justify-content: center;

}
.make_bids_btn_number__GoBC4 {
    width: 22px;
    height: 17px;
    border: 1px solid #ccc;
    cursor: pointer;
    color: #ccc;
    background: none;
    border-radius: 12px;
    text-align: center;
  
}
.make_bids_grid_item_number__2sLv4{
    border: none;
    border-radius: 24px;
    background-color: rgba(20,20,20,255);
    color: #6d6b78;
    text-align: center;
}
.make_bids_bid_commit__CHeVN{
    margin: 20px 10px;
    display: flex;
    flex-direction: column;
    grid-gap: 3px;
    gap: 3px;
}
.make_bids_chose_bids__1G4yb{
    background-color: rgba(13,13,13,255);
    display: flex;
    padding: 0px 18px;
    padding-left: 0;
    border-radius: 20px;
    margin-top: 0.5rem;
    align-items: center;
    position: relative;
}


.make_bids_chose_buttons__mSTD-{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns of equal width */
    grid-template-rows: repeat(2, 1fr); /* Two rows of equal height */
    grid-gap: 5px;
    gap: 5px; /* Gap between grid items */
}

.make_bids_type_bidding_active__ZEIMh{
    align-items: center;
    display: flex;
    color: aliceblue;
    background-color: #2c2d31;
    border-radius: 24px;

}
.make_bids_type_bidding__3QD70{
    padding: 5px 0 ;
    font-weight: 300;
}

.make_bids_type_bidding__3QD70 span:hover{
    color: red;
    cursor: pointer;
}
.make_bids_chose_type_bidding__10rAT .make_bids_my__1KyAJ{
    padding-left: 40px;
    padding-right: 40px;
}
.make_bids_chose_type_bidding__10rAT .make_bids_all__1Rkyd{

    padding-left: 40px;
    padding-right: 40px;
}
.make_bids_chose_type_bidding__10rAT{
    padding: 0;
}
.make_bids_color__1E93d{
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(39,159,9,255);
    border: 1px solid #e1e7eda1;
    border-radius: 17px;
    height: 100%;
}

.make_bids_await_round__NS87V{
    font-size: 15px;
    text-align: center;
    color: rgb(201, 201, 201);
}

/* ----  AUTO BID-------- */
.make_bids_switch__23yDX {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 100%;
}

.make_bids_switch__23yDX input {
    opacity: 0;
    width: 0;
    height: 0;
}
.make_bids_slider__2Dg9z {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.make_bids_slider__2Dg9z:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 16px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}
  
input:checked + .make_bids_slider__2Dg9z {
    background-color: #279f09;
}
  
input:focus + .make_bids_slider__2Dg9z {
    box-shadow: 0 0 1px #279f09;
} 
  
input:checked + .make_bids_slider__2Dg9z:before {
    transform: translateX(16px);
} 

.make_bids_oto__2GCCt{
    display: none;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
.make_bids_oto__2GCCt p{
    font-size: 10px;
}
.make_bids_auto_bid__1VCXT{
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}
.make_bids_auto_cash__3OT6I{
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}
.make_bids_how_much_bid__3tCs6{
    font-weight: 600;
    font-family: 'Roboto'; 
    text-shadow: 1px 1px 2px rgba(11, 15, 1, 3);
}

.make_bids_button_make_bid__19Bpv{
    font-weight: 300;
    font-family: 'Roboto'; 
    text-shadow: 1px 1px 2px rgba(11, 15, 1, 3);
    
}
