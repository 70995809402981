.message_alert{
    display: flex;
    flex-direction: row;
    align-items: center;
    border:1px solid greenyellow;
    padding-left: 30px;
    width: 290px;
    border-radius: 54px;
    justify-content: space-between;
    background-color: #034203;
    transition: transform 1s ease-in-out;
    
}
.announcement, .win_info{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.announcement{
    padding:10px;
}

.win_info{
    padding: 2px 21px;
    border-radius: 33px;
    border: 1px solid #f0f8ff82;
    margin: 5px ;
  background-color: #279f09;
  position: relative;
  overflow: hidden;
  height: 42px;
  justify-content: center;
}
.star_img{
  position: absolute;
  top: -4px;
  left: -11px;
  width: 122%;
  height: 151%;
  z-index: 0;
}
.close_button::before {content: 'x';  
    color: #8b8a8a;
    font-weight: 900;
    font-family: Arial, sans-serif;
    content: 'x';  
}

#win_amount{
    text-shadow: 1px 1px 2px rgba(11, 15, 1, 3);
    color: white;
}
