/* ------------------'Coordinates'  game----------*/
.y_axis{
    position: absolute;
    height: 95%;
    width: 5%;
    overflow: hidden;
}
.x_axis{
    position: absolute;
    width: 96%;
    bottom: 0;
    right: -1%;
    height: 6%;
    overflow: hidden;
}
.coordinate_x, .coordinate_y{
    background-color: rgb(240 248 255 / 21%);
    position: absolute;
    
}

.coordinate_y{
    width: 1px;
    height: 99%;
    right: 0;
}
.coordinate_x{
    height: 1px;
    width: 100%;
}
.x_dots{
    display: flex;
    flex-direction: row;
    gap: 10%;
    align-items: center;
    height: 100%;
    padding-left: 5%;
    animation: moveRightToLeft 25s linear infinite;
    
}
.y_dots{
    display: flex;
    flex-direction: column;
    gap: 9%;
    height: 97%;
    align-items: center;
    animation: moveUpToDown 25s linear infinite;
}
.dot_right{
    background: #06619a;
}
.dot_left{
    background: white;
}

.dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
}
#x_dots_clone{
    position: relative;
    right: -94%;
    top: -103%;
    animation: moveRightToLeft 25s linear infinite;
}

#y_dots_clone{
    bottom: 194%;
    position: relative;
    animation: moveUpToDown 25s linear infinite;
}
@keyframes moveRightToLeft {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}
@keyframes moveUpToDown {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(100%);
    }
}
 