.right{
    width: 100%;
    flex-basis: 75%;
    display: grid;
    grid-auto-rows: 0fr 10fr 3fr;
}
/* --------------------PreviousCoefs---------------------------- */
.basic_old_coef_div{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 12px;
    align-items: center;
    justify-content: center;
    max-height: 15px;
    position: relative;
}
.upper_field_old_coefs{
    margin-bottom: 0;
}
.lower_old_coef_list{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 3px 12px;
    align-items: center;
    justify-content: start;
    height: 100%;
    width: 100%;
}
.lower_field_old_coefs{
    background-color: rgb(41 41 49);
}
.hidden_old_coef{
    font-size: 12px;
}
.old_coef{
    border-radius: 24px;
    background-color: black;
    padding: 1px 10px;
    font-size: 14px;
    font-weight: 600;
    
}

.make_darker{
    height: 46px;
    position: absolute;
    width: 65%;
    top: 7%;
    left: 37%;
    background: linear-gradient(to right, rgb(1 1 1 / 0%), rgb(0 0 0 / 49%));
}
.more_old_coefs{
    z-index: 1;
    position: absolute;
    border-radius: 24px;
    background-color: #2c2c2e;
    overflow: hidden;
    border: 1px solid rgba(240, 248, 255, 0.20);
}
.images_old_coefs{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
}
.more_old_coefs:hover{
    cursor: pointer;
}
.heart_more_coefs{
    transition: transform 0.5s ease;
}
.lessThan2{
    color: rgb(89 173 233);
}
.between2and10{
    color: #8c46f1;
}
.moreThan10{
    color: #C628a2;
}

/* - -----------GAME_--------------------------------------*/
.game {
    position: relative;
    overflow: hidden;
    margin: 1em;
    margin-bottom: 10px;
    margin-top: 0;
}

.viewport {
    width: 100%; 
    height: 100%;
    display: inline-block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #f0f8ff1c;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 35px;
}

.rotating_image {
    position: absolute;
    width: 200%;
    height: 180%;
    right: -4%;
    top: 7%;
    scale: 280%;
    animation: rotate 30s linear infinite;
    /* animation-play-state: paused; */
    object-fit: cover;
    transform-origin: center;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg); /* Start from 0 degrees */
    }
    100% {
        transform: rotate(360deg); /* Rotate to 360 degrees (full circle) */
    }
}
/* ------ PLANE aviator---------- */
.plane{
    width: 150px; 
    height: 150px;
    background-image: url('./assets/aviator.gif'); /* URL to your plane image */
    background-size: cover;
    position: absolute;
    z-index: 10;
}
svg{
    position: absolute;
}

.plane_flying{
    animation: takeOff 7s ease-in-out, upAndDown 5s ease-in-out infinite
}


.plane_flyaway{
    animation: away 2s ease-in-out
}
@keyframes takeOff {
    0% {
        bottom: 0%;
        left: 0%;
        transform: translate(10%, 10%);
    }
    
    67%{
        bottom: 70%; 
        left: 60%;
        transform: translate(60%, 10%);
    }
    100%{
        bottom: 70%; 
        left: 60%;
        transform: translate(60%, 1000%);
    }
  }

@keyframes upAndDown {
    0%, 100% {  
        transform: translate(14%, 10%);
    }
    97%{
        transform: translate(14%, 10%);
    }
    50% {

      transform: translate(60%, 89%);
    }
    100%{
        transform: translate(14%, 10%);
    }
  }

@keyframes away {
    0% {
        transform: translate(0, 0);
    }
    100% {
        left: 110%;
        bottom:110% ;
        transform: translate(-10%, -50%);
    }
}




.gone_away{
    font-size: 30px;
    position: absolute;
    top: 34%;
    left: 43%;
    
    font-weight: 600;
}
