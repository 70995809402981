/* -------------------TIME BAR ---------------*/
.time_bar{
    width: 100%;
    background-color: #1b1c1e;
    border-radius: 24px;
    height: 6px;
    
}
.time_bar .filler{
    height: 6px;
    background: #e20637;
    border: 24px;
    animation: no_width 5s linear infinite;

}

@keyframes no_width{
  0%{
    width: 100%;
  }
  100%{
    width: 0%;
  }
}
/*------------------------- StartRound ---------------*/
.propeller{
    background-image: url('../../assets/propeller.png');
    width: 149px;
    height: 141px;
    background-size: cover;
    position: absolute;
    bottom:111%;
    right: 32%;
    animation: rotate 1.2s linear infinite;
}

@keyframes rotate {
    0% {
      transform: rotate(0deg); /* Rotate 0 degrees at the start */
    }
    100% {
      transform: rotate(360deg); /* Rotate 360 degrees at the end */
    }
  }

.start_round{
    position: absolute;
    top: 50%;
    left: 32%;
    font-family: 'Roboto';
}
.start_round h3{
    font-size: 40px;
    font-weight: 300;
}